import React from "react"
import NavBar from "../components/NavBar";



// import ProjectCard from "../components/ProjectCard";

const Home = () => {


  return (
    <>
 <NavBar/>
    <div style={{backgroundColor:'#F5F5F5' ,height:'100%',width:'100%', display:'block'}}>
    <div style={{width:"100%",height:'100vh'}}>
    <h1 className="text-3xl font-bold ">
      HOME
      </h1>

    </div>
</div>

     

    </>
  )
};

export default Home
