import React from "react"

const Team = () => {
  return (
    <div>
      TEAM PAGE
    </div>
  )
};

export default Team
