import React from "react"

const Messaging = () => {
  return (
    <div>
      Messaging
    </div>
  )
};

export default Messaging
